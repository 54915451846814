<template>
	<div class="sticker-party" :class="!config.partyModeEnabled && 'sticker-party-disabled'">
		<page-title title="Sticker Party"></page-title>
		<v-row class="mt-2">
			<v-col cols="12" md="6" class="mt-3">
				<PartyMode
					:config="config"
					@onRangeDataStart="actions.onRangeDataStart"
					@onRangeDataEnd="actions.onRangeDataEnd" />
			</v-col>
			<v-col cols="12" md="6" class="mt-3">
				<How></How>
				<Meter :config="config" class="mt-5" />
			</v-col>
		</v-row>
		<NonAffiliatePopup :modal="showNoBitsPopup" @close="showNoBitsPopup = false">
			<template>Sorry! You must be Twitch Affiliate level or higher to enable party mode.</template>
		</NonAffiliatePopup>
	</div>
</template>
<script>
import axios from '@axios';
import { onBeforeUnmount, reactive, ref } from '@vue/composition-api';
import store from '@/store';
import { isBitsEnabled } from '../../utils/auth';
import { streamerConfig } from '../../utils/sticker';
import { FormChangeWatcher } from '../../utils/form';
import PageTitle from '@/components/PageTitle.vue';
import How from './sticker-party/How.vue';
import PartyMode from './sticker-party/PartyMode.vue';
import Meter from './sticker-party/Meter.vue';
import NonAffiliatePopup from '@/components/NonAffiliatePopup';

export default {
	components: {
		PageTitle,
		How,
		PartyMode,
		Meter,
		NonAffiliatePopup,
	},
	setup() {
		const config = reactive(streamerConfig);
		const showNoBitsPopup = ref(false);

		// Create watcher for data changes.
		const formChangeWatcher = new FormChangeWatcher();
		onBeforeUnmount(() => {
			formChangeWatcher.stopWatching();
		});

		const actions = {
			loadData: () => {
				formChangeWatcher.stopWatching();

				axios.get('/config').then(res => {
					Object.assign(config, res.data);
					config.isLoaded = true;
					formChangeWatcher.startWatching(config, actions.saveChanges);
				});
			},
			saveChanges: () => {
				// No bits streamers cannot enable party mode.
				if (!isBitsEnabled.value) {
					// Reset party mode.
					if (config.partyModeEnabled) {
						formChangeWatcher.stopWatching();
						config.partyModeEnabled = false;
						formChangeWatcher.startWatching(config, actions.saveChanges);

						// Show popup, only if tutorial isn't being shown.
						if (!store.state.app.showTutorial) {
							showNoBitsPopup.value = true;
						}
					}
				} else {
					axios.put('/config', config).catch(error => console.log(error));
				}
			},
			onRangeDataStart: () => {
				formChangeWatcher.onRangeDataStart();
			},
			onRangeDataEnd: () => {
				formChangeWatcher.onRangeDataEnd();
			},
		};

		actions.loadData();

		return {
			actions,
			config,
			showNoBitsPopup,
		};
	},
};
</script>
