<template>
	<div>
		<v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>
		<v-card>
			<v-card-text>
				<strong class="fs--22 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
					Sticker Party Meter
				</strong>
				<p class="fs--15 fw--300" :class="!config.partyModeEnabled && 'disabledForm--text'">
					Drag to reposition screen placement
				</p>
				<!-- <vue-resizable>
					<div class="placement"></div>
				</vue-resizable> -->
				<div class="placement mb-3" ref="partyMeterContainer">
					<span class="secondary--text text-uppercase window-text">Stream Window</span>
					<vue-draggable-resizable
						@dragstop="actions.onMeterDragStop"
						:w="147"
						:h="50"
						:x="meterDropPosition ? meterDropPosition.x : 0"
						:y="meterDropPosition ? meterDropPosition.y : 0"
						:resizable="false"
						:draggable="config.partyModeEnabled && !config.partyModeSeparateView"
						:parent="true">
						<img
							v-if="!config.partyModeSeparateView"
							:width="145"
							:height="50"
							src="@/assets/images/misc/sticker-party.png"
							alt="Sticker Party"
							ref="partyMeterImage" />
					</vue-draggable-resizable>
				</div>

				<strong class="fs--16 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
					Display Party Meter in Separate Browser Source
				</strong>

				<div class="d-flex justify-space-between align-end">
					<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
						Enable:
					</strong>
					<v-switch
						v-model="config.partyModeSeparateView"
						color="pinkItem"
						:disabled="!config.partyModeEnabled"
						hide-details></v-switch>
				</div>

				<v-row class="mt-1">
					<v-col cols="9" md="9" class="form-left">
						<div class="code-copy purple px-3 py-2">
							<span class="">{{ link }}</span>
						</div>
						<!-- <v-text-field class="form-field" placeholder="http://oi32gj.weg/zzzzr/93250"
							background-color="purple" dense color="purple" solo hide-details></v-text-field> -->
					</v-col>
					<v-col cols="3" md="3" class="form-right">
						<v-btn
							depressed
							color="primary"
							block
							:disabled="!config.partyModeEnabled"
							@click="actions.copy"
							>Copy</v-btn
						>
					</v-col>
				</v-row>

				<div class="mt-2">
					<span class="fieldFore--text fs--12 fw--400">
						Allows you to render the Party Meter in a separate browser source, giving you more control over
						it's position on each scene. Requires you configure a NEW browser source in addition to your
						main sticker layer browser source.
					</span>
					<a
						class="fs--12 pink--text"
						:class="!config.partyModeEnabled && 'disabled-link'"
						href="https://discordapp.com/invite/2ePbGrc"
						target="_blank"
						>Need Help?</a
					>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { getEndpointHost } from '../../../utils/config';
import { getUserId } from '../../../utils/auth';
import { isNumber, isArray } from '../../../utils/utils';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup(props) {
		const link = `https://${getEndpointHost()}:8081/partymeter/${getUserId()}/mt`;
		const copied = ref(false);

		const partyMeterContainer = ref(null);
		const partyMeterImage = ref(null);

		// Screen center.
		const dimensions = computed(() => {
			const d = {
				center: {
					x: 0,
					y: 0,
				},
			};

			if (partyMeterContainer.value) {
				d.center.x = partyMeterContainer.value.clientWidth / 2;
				d.center.y = partyMeterContainer.value.clientHeight / 2;
			}

			return d;
		});

		// The current x / y position of the meter.
		const meterDropPosition = computed(() => {
			const d = dimensions.value;
			const pos = {
				x: dimensions.value.center.x,
				y: dimensions.value.center.y,
			};

			if (props.config.isLoaded) {
				const pmp = props.config.partyModePosition;
				if (isArray(pmp)) {
					if (isNumber(pmp[0]) && isNumber(pmp[1])) {
						pos.x = d.center.x + pmp[0] * d.center.x;
						pos.y = d.center.y + pmp[1] * d.center.y;
					}
				}
			}

			//console.log(`Meter drop position:\n${JSON.stringify(pos, null, 4)}`);

			return pos;
		});

		const actions = {
			// Party meter movement, save when drag is complete.
			onMeterDragStop: (x, y) => {
				const dropPosition = {
					x: x,
					y: y,
				};
				const d = dimensions.value;
				const center = d.center;

				const dropTranslation = {
					x: (dropPosition.x - center.x) / center.x,
					y: (dropPosition.y - center.y) / center.y,
				};

				props.config.partyModePosition = [dropTranslation.x, dropTranslation.y];

				//console.log(`Stop drag: x: ${x}, y: ${y}`);
				//console.log(`Dimensions:\n${JSON.stringify(d, null, 4)}`);
				//console.log(`Drop position:\n${JSON.stringify(dropPosition, null, 4)}`);
				//console.log(`Drop translation:\n${JSON.stringify(dropTranslation, null, 4)}`);
				//console.log(`Party meter image width: ${partyMeterImage.value.width}`);
			},
			// Copy the OBS URL to the clipboard.
			copy: () => {
				navigator.clipboard.writeText(link).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
		};

		return {
			actions,
			link,
			partyMeterContainer,
			partyMeterImage,
			meterDropPosition,
			copied,
		};
	},
};
</script>

<style lang="scss" scoped>
.placement {
	position: relative;
	height: 250px;
	width: 100%;
	max-width: 100%;
	background-color: var(--v-fieldBack-base);
	border: 2px solid var(--v-secondary-base);
	border-radius: 4px;
}

.window-text {
	position: absolute;
	left: 40%;
	top: 50%;
}

.code-copy {
	border-radius: 4px;
	width: 100%;
	color: #a282f5;
}

.disabled-link {
	pointer-events: none;
}

.vdr {
	border: none !important;
	background: none !important;
}

@media screen and (max-width: 600px) {
	.window-text {
		position: absolute;
		left: 27%;
		top: 50%;
	}
}
</style>
