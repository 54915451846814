<template>
	<v-card min-height="827">
		<v-card-text>
			<section class="pb-4">
				<h3 class="fs--22 fw--400">Party Mode</h3>
				<div class="d-flex justify-space-between align-end">
					<h3 class="fs--16 fw--400">Enable</h3>
					<v-switch v-model="config.partyModeEnabled" color="pink" hide-details></v-switch>
				</div>
				<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">Enables party mode functionality.</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<v-row no-gutters class="align-center mb-0">
					<v-col cols="8" sm="10" md="9" lg="10" xl="10" class="pr-sm-3">
						<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
							Party Meter Bits Goal:
						</strong>
					</v-col>
					<v-col cols="4" sm="2" md="3" lg="2" xl="2">
						<input
							v-model="config.partyModeGoal"
							type="number"
							oninput="validity.valid||(value='');"
							class="number-input"
							@keydown="$emit('onRangeDataStart')"
							@focusout="$emit('onRangeDataEnd')"
							:disabled="!config.partyModeEnabled"
							:class="!config.partyModeEnabled && 'disabled-input'" />
						<!-- <input
							v-model="meter_bits"
							@keyup="parseNumber"
							@keydown="submitStart"
							@focusout="submitEnd"
							type="text"
							oninput="validity.valid||(value='');"
							class="number-input" /> -->
					</v-col>
					<v-col cols="12" v-if="errors.bitsError.value">
						<small class="error--text"
							>The party goal must be between {{ minGoal.toLocaleString() }} and
							{{ maxGoal.toLocaleString() }} Bits.</small
						>
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mt-3 mb-0">
					Number of bits the community must use on stickers to start the party. Value can be set between
					{{ minGoal.toLocaleString() }} - {{ maxGoal.toLocaleString() }} Bits.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pt-3 pb-4">
				<v-row no-gutters align-sm="center" class="mb-0">
					<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
						<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
							Sticker Countdown:
						</strong>
					</v-col>
					<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
						<v-slider
							v-model="partyModeCooldownTime"
							@start="$emit('onRangeDataStart')"
							@end="$emit('onRangeDataEnd')"
							min="0"
							max="10"
							:thumb-color="config.partyModeEnabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="config.partyModeEnabled ? 'pinkItem' : 'disabledForm'"
							track-color="purpleDivider"
							:disabled="!config.partyModeEnabled"
							hide-details></v-slider>
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mb-0">
					The waiting time until the same viewer can place another sticker (range is between 0 and 10 seconds)
					when Party Mode is active.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pb-4">
				<div class="d-flex justify-space-between align-end">
					<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
						Sticker Party Music
					</strong>
					<v-switch
						v-model="config.partyModeMusic"
						color="pink"
						:disabled="!config.partyModeEnabled"
						hide-details></v-switch>
				</div>
				<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
					Enables or Disables the music during sticker party.
				</p>
				<TrackSelection :config="config" />

				<v-row no-gutters align-sm="center" class="mb-0 mt-3">
					<v-col cols="12" sm="5" md="5" lg="4" class="pr-sm-3">
						<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
							Track Volume:
						</strong>
					</v-col>
					<v-col cols="12" sm="7" md="7" lg="8" class="mt-2 mt-sm-0">
						<v-slider
							v-model="config.partyMusicVolume"
							@start="$emit('onRangeDataStart')"
							@end="$emit('onRangeDataEnd')"
							:min="0"
							:max="100"
							:thumb-color="config.partyModeEnabled ? 'pinkItem' : 'disabledForm'"
							thumb-label
							:track-fill-color="config.partyModeEnabled ? 'pinkItem' : 'disabledForm'"
							track-color="purpleDivider"
							:disabled="!config.partyModeEnabled"
							hide-details></v-slider>
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mt-1 mb-0">Adjust Track Volume to play on the stream.</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="pb-4">
				<div class="d-flex justify-space-between align-end">
					<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
						Animated Confetti:
					</strong>
					<v-switch
						v-model="config.partyModeConfetti"
						color="pink"
						:disabled="!config.partyModeEnabled"
						hide-details></v-switch>
				</div>
				<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
					Displays Animated Party confetti during while sticker party is active.
				</p>
			</section>

			<v-divider class="purpleDivider"></v-divider>

			<section class="py-4">
				<strong class="fs--16 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'"
					>Optimize Sticker Party Performance</strong
				>
				<div class="d-flex justify-space-between align-end mt-1 mb-3">
					<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
						Disable Roll On/Off animations:
					</strong>
					<v-switch
						v-model="config.partyModeDisabledAnimation"
						color="pink"
						:disabled="!config.partyModeEnabled"
						hide-details></v-switch>
				</div>
				<v-row align="center" class="mb-0">
					<v-col cols="8" sm="10" md="9" lg="10" xl="10">
						<strong class="fs--14 fw--400" :class="!config.partyModeEnabled && 'disabledForm--text'">
							Limit Stickers:
						</strong>
					</v-col>
					<v-col cols="4" sm="2" md="3" lg="2" xl="2">
						<input
							v-model="config.partyModeLimitStickerCount"
							type="number"
							oninput="validity.valid||(value='');"
							class="number-input"
							@keydown="$emit('onRangeDataStart')"
							@focusout="$emit('onRangeDataEnd')"
							:disabled="!config.partyModeEnabled"
							:class="!config.partyModeEnabled && 'disabled-input'" />
						<!-- <input
							v-model="limit_stickers"
							@keyup="parseNumber"
							@keydown="submitStart"
							@focusout="submitEnd"
							type="text"
							oninput="validity.valid||(value='');"
							:disabled="!config.partyModeEnabled"
							class="number-input"
							:class="!config.partyModeEnabled && 'disabled-input'" /> -->
					</v-col>
				</v-row>
				<p class="fieldFore--text fs--12 fw--400 mb-0">
					These options will help limit the number of stickers that can be rendered on screen as well as
					disable the rolling animations in the event you experience slowdown on less powerful computers. By
					default, a limit of 0 means there can be an unlimited number of stickers applied.
				</p>
			</section>
		</v-card-text>
	</v-card>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { rangeValueConverter } from '../../../utils/sticker';
import TrackSelection from './TrackSelection.vue';
export default {
	components: {
		TrackSelection,
	},
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup(props, ctx) {
		const minGoal = ref(1000);
		const maxGoal = ref(20000);

		// Calculated UI value for cooldown.
		const partyModeCooldownTime = computed({
			get() {
				return rangeValueConverter.toUiValue(props.config.partyModeCooldownTime, 0, 10, 100);
			},
			set(newValue) {
				props.config.partyModeCooldownTime = rangeValueConverter.toDbValue(newValue, 0, 10, 100);
			},
		});

		const meter_bits = ref(Number(props.config.partyModeGoal).toLocaleString());
		const limit_stickers = ref(Number(props.config.partyModeLimitStickerCount).toLocaleString());

		const errors = {
			bitsError: computed(() => {
				if (props.config.partyModeGoal != null) {
					return (
						Number(props.config.partyModeGoal) < minGoal.value ||
						Number(props.config.partyModeGoal) > maxGoal.value
					);
				}
				return false;
			}),
		};

		const parseNumber = () => {
			const isValidBits = Number(meter_bits.value.replace(/\D/g, ''));

			if (!isValidBits) {
				meter_bits.value = String(1000).toLocaleString();
			}

			const mode = meter_bits.value.replace(/\D/g, '');
			meter_bits.value = Number(mode).toLocaleString();

			const isValidLimit = Number(limit_stickers.value.replace(/\D/g, ''));

			if (!isValidLimit) {
				limit_stickers.value = String(0).toLocaleString();
			}

			const limit = limit_stickers.value.replace(/\D/g, '');
			limit_stickers.value = Number(limit).toLocaleString();
		};

		const submitStart = () => {
			const mode = Number(meter_bits.value.replace(/\D/g, ''));
			props.config.partyModeGoal = mode;

			const limit = Number(limit_stickers.value.replace(/\D/g, ''));
			props.config.partyModeLimitStickerCount = limit;

			ctx.emit('onRangeDataStart');
		};

		const submitEnd = () => {
			const mode = Number(meter_bits.value.replace(/\D/g, ''));
			props.config.partyModeGoal = mode;

			const limit = Number(limit_stickers.value.replaceAll(/\D/g, ''));
			props.config.partyModeLimitStickerCount = limit;

			ctx.emit('onRangeDataEnd');
		};

		watch(props.config, newConfig => {
			meter_bits.value = Number(newConfig.partyModeGoal).toLocaleString();
			limit_stickers.value = Number(newConfig.partyModeLimitStickerCount).toLocaleString();
		});

		const actions = {};

		return {
			minGoal,
			maxGoal,
			actions,
			partyModeCooldownTime,
			errors,
			meter_bits,
			limit_stickers,
			parseNumber,
			submitStart,
			submitEnd,
		};
	},
};
</script>

<style lang="scss" scoped>
// .theme--dark.v-input--switch .v-input--switch__thumb
.sticker-party input.number-input {
	background: var(--v-purple-base);
	height: 30px;
	width: 100%;
	outline: none;
	color: white;
	padding: 10px;
	text-align: center;
	border-radius: 4px;
}

.disabled-input {
	color: var(--v-disabledForm-base) !important;
}
</style>
