/**
 * Form utilities.
 */
import { onBeforeUnmount, watch } from '@vue/composition-api';
/**
 * Frequent data changes occur with sliders and text fields.
 * We ignore data changes until data has been "finalized", e.g.
 * after a slide ends or text field loses focus.
 *
 * @param data Data to be watched for changes.
 * @param saveChangesCallback Function call to save data.
 * @param beforeChangesCallback Optional callback that is called before
 * the save callback is executed. The consumer should return a false
 * value to stop execution of the save callback.
 *
 */
class FormChangeWatcher {
	startWatching(data, saveChangesCallback, onChangeCallback) {
		this.data = data;
		this.saveChangesCallback = saveChangesCallback;
		this.onChangeCallback = onChangeCallback;

		this.stopWatching();

		this.watchStopHandle = watch(data, () => {
			if (!this.isRangeDataActive) {
				if (!this.onChangeCallback || this.onChangeCallback() == true) {
					this.saveChangesCallback(this.data);
				}
			}
		});
	}

	stopWatching() {
		if (this.watchStopHandle) {
			this.watchStopHandle();
			this.watchStopHandle = null;
			this.data = null;
			this.saveChangesCallback = null;
		}
	}

	onRangeDataStart() {
		this.isRangeDataActive = true;
	}

	// Slider or text change change is complete so we save changes.
	onRangeDataEnd() {
		this.isRangeDataActive = false;
		this.saveChangesCallback(this.data);
	}
}

export { FormChangeWatcher };
