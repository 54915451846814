<template>
	<div class="track-selection mt-3">
		<div class="d-flex align-center">
			<strong class="fs--14 fw--400" :class="!isMusicEnabled && 'disabledForm--text'"> Track Selection </strong>
			<v-chip color="pink" class="ml-2" small>New</v-chip>
		</div>

		<p class="fieldFore--text fs--12 fw--400 mt-2 mb-2">
			Enabled music tracks will play at random during a Sticker Party. You can disable or enable individual tracks
			to suit your preferences.
		</p>
		<p class="fieldFore--text fs--12 fw--400 mt-2 mb-0">
			All tracks are licensed to be used with this extension and are DMCA cleared for live streaming.
		</p>

		<v-row no-gutters>
			<v-col cols="12" sm="7" md="12" lg="7" xl="7">
				<v-data-table
					class="activity-feed-table"
					:headers="headers"
					:items="config.stickerPartyTracks"
					:items-per-page="5"
					:hide-default-footer="!config.stickerPartyTracks || config.stickerPartyTracks.length < 1">
					<template v-slot:[`item.enabled`]="{ item }">
						<v-checkbox
							v-model="item.isEnabled"
							:disabled="!isMusicEnabled"
							color="pink"
							class="ma-0"
							hide-details></v-checkbox>
					</template>
					<template v-slot:[`item.title`]="{ item }">
						<strong class="fs--14 fw--400" :class="!isMusicEnabled && 'disabledForm--text'">
							{{ item.name }}
						</strong>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-btn icon @click="actions.playTrack(item)" :disabled="!isMusicEnabled">
							<v-icon
								v-if="!playingTrack || (playingTrack && playingTrack.id !== item.id)"
								color="pink"
								>{{ icons.play }}</v-icon
							>

							<v-icon v-if="playingTrack && playingTrack.id === item.id" color="pink">{{
								icons.pause
							}}</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-col>
			<v-col
				v-if="$vuetify.breakpoint.smAndUp"
				cols="12"
				sm="5"
				md="12"
				lg="5"
				xl="5"
				order="first"
				order-sm="last"
				order-md="first"
				order-lg="last"
				order-xl="last"
				align-self="center"
				class="text-center">
				<template v-if="$vuetify.breakpoint.smOnly">
					<img
						src="@/assets/images/mascot/UpdatedDJ.png"
						height="90%"
						width="90%"
						alt="Mascott DJ"
						class="ml-5" />
				</template>
				<template v-if="$vuetify.breakpoint.mdOnly">
					<img
						src="@/assets/images/mascot/UpdatedDJ.png"
						height="100%"
						class="ml-5"
						width="80%"
						alt="Mascott DJ" />
				</template>
				<template v-if="$vuetify.breakpoint.lgAndUp">
					<img src="@/assets/images/mascot/UpdatedDJ.png" width="125%" class="ml-2" alt="Mascott DJ" />
				</template>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { computed, ref, onUnmounted } from '@vue/composition-api';
import { mdiPlayCircle, mdiPauseCircle } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},

	setup(props) {
		const headers = [
			{
				text: 'Enable',
				align: 'start',
				sortable: true,
				value: 'enabled',
			},
			{ text: 'Track Title', value: 'title', sortable: true, filterable: true, align: 'start' },
			{ text: 'Preview', value: 'action', sortable: false, filterable: false, align: 'end' },
		];

		const playingTrack = ref(false);
		let audio = null;

		const isMusicEnabled = computed(() => props.config.partyModeEnabled && props.config.partyModeMusic);

		// Calculate track volume.
		const volume = computed(() => {
			const c = props.config;

			let masterVolume = c.masterVolume;
			if (masterVolume === undefined || masterVolume === null) masterVolume = 50;

			let partyMusicVolume = c.partyMusicVolume;
			if (partyMusicVolume === undefined || partyMusicVolume === null) partyMusicVolume = 100;

			const maxRange = 100;
			return (masterVolume / maxRange) * (partyMusicVolume / maxRange);
		});

		const actions = {
			playTrack(track) {
				// If same Track is already playing then pause
				if (playingTrack.value && playingTrack.value.id === track.id && audio) {
					audio.pause();
					playingTrack.value = null;
					return;
				}

				playingTrack.value = { ...track };
				// If any other track is playing then pause that before playing new track
				if (audio) {
					audio.pause();
				}

				audio = new Audio(track.url);
				audio.volume = volume.value;
				audio.play();

				audio.addEventListener('ended', () => {
					audio = null;
					playingTrack.value = null;
				});
			},
		};

		onUnmounted(() => {
			if (audio) {
				audio.pause();
				audio = null;
				playingTrack.value = null;
			}
		});

		return {
			headers,

			isMusicEnabled,
			playingTrack,
			audio,

			actions,

			icons: {
				play: mdiPlayCircle,
				pause: mdiPauseCircle,
			},
		};
	},
};
</script>
